<!-- 合同信息 -->
<template>
  <div class="info_module">
    <h1>合同信息</h1>
    <div class="module_main">
      <div class="contract_wrapper">
        <div class="left_steps">
          <el-steps
            direction="vertical"
            finish-status="success"
            :active="curStep">
            <el-step title="乙方填写合同信息"></el-step>
            <el-step title="甲乙双方签字"></el-step>
            <el-step title="确认合作协议"></el-step>
          </el-steps>
        </div>
        <div class="right_main">
          <!-- 下载协议 -->
          <step-three
            v-if="curStep = 3"
            :contractResult="detail.contractResult">
          </step-three>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stepThree from './step-three.vue'
export default {
  components: { stepThree },
  data () {
    return {
      curStep: 3
    }
  },
  props: {
    // 需求详情
    detail: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
  .contract_wrapper {
    display: flex;
    padding: 10px 0 30px 0;
    .left_steps {
      flex: 0 0 230px;
      height: 150px;
    }
    .right_main {
      flex: 1;
    }
  }
</style>
