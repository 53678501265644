<template>
  <div class="step_box">
    <h1>最终合作协议</h1>
    <info-item
      label="最终合作协议"
      label-width="120px">
      <el-button
        type="primary"
        plain
        size="small"
        @click="onDownload">
        {{ file.originName }}
      </el-button>
    </info-item>
  </div>
</template>

<script>

export default {
  props: {
    // 合同信息对象
    contractResult: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    // 合同文件对象
    file () {
      return this.contractResult.finalContract
    }
  },
  methods: {
    // 下载合同
    onDownload () {
      window.open(this.file.url)
    }
  }
}
</script>

<style lang="scss" scoped>
  .step_box {
    width: 470px;
    & > h1 {
      font-size: 14px;
      line-height: 40px;
      color: #303133;
      margin-bottom: 10px;
    }
  }
</style>
