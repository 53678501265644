<!--
  需求详情
 -->
<template>
  <div class="detail">
    <detail-header title="需求详情" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <!-- 基本信息 -->
        <div class="info_module base_info">
          <h1>基本信息</h1>
          <div class="module_main">
            <info label-width="140px">
              <info-item
                label="需求名称">
                {{ detail.title }}
              </info-item>
              <info-item
                label="需求类别">
                {{ detail.serviceTypeName }}
              </info-item>
              <info-item
                label="行业领域">
                {{ detail.industryName ? detail.industryName.join(',') : '' }}
              </info-item>
              <info-item
                label="技术领域">
                {{ detail.technicalName ? detail.technicalName.join(',') : '' }}
              </info-item>
              <info-item
                label="时限要求">
                {{ detail.startAt }} - {{ detail.endAt }}
              </info-item>
              <info-item
                label="需求描述">
                {{ detail.description }}
              </info-item>
              <info-item
                label="关键字">
                {{ detail.keywords }}
              </info-item>
              <info-item
                label="预算金额">
                {{ detail.budget }}元
              </info-item>
              <info-item
                label="需求所属企业名称">
                {{ detail.enterpriseName }}
              </info-item>
              <info-item
                label="统一社会信用代码">
                {{ detail.socialCreditCode }}
              </info-item>
              <info-item
                label="企业所在区域">
                {{ detail.province }}
              </info-item>
              <info-item
                label="联系人">
                {{ detail.contacts }}
              </info-item>
              <info-item
                label="联系手机">
                {{ detail.contactMobile }}
              </info-item>
              <info-item
                label="联系邮箱">
                {{ detail.contactEmail }}
              </info-item>
              <info-item
                v-if="detail.remarks"
                label="客服备注">
                {{ detail.remarks }}
              </info-item>
            </info>
          </div>
        </div>

        <template v-if="showModules">
          <!-- 诊断报告 -->
          <diagnostic-report
            v-if="showDiagnosticReport"
            :detail="detail">
          </diagnostic-report>

          <!-- 报价 -->
         <discuss-price
            v-if="showDiscussPrice"
            :detail="detail">
          </discuss-price>

          <!-- 合同信息 -->
          <contract-info
            v-if="showContractInfo"
            :detail="detail">
          </contract-info>

          <sub-require
            v-if="showSubRequire"
            :detail="detail">
          </sub-require>

          <div class="info_module">
            <h1>需求状态</h1>
            <div class="module_main">
              <info label-width="140px">
                <info-item
                  label="需求状态">
                  {{ detail.logicStatus | findLabel(globalVar.REQUIRE_STATUS_OPTIONS) }}
                </info-item>
                <info-item
                  v-if="showServiceOrg"
                  label="服务提供方">
                  <template v-if="this.isDistributorReq">
                    {{ detail.distributorOrgName }}  {{ detail.distributorName }}
                  </template>
                  <template v-else-if="this.isReceiverReq">
                    {{ detail.receiverOrgName }}  {{ detail.receiverName }}
                  </template>
                </info-item>
              </info>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import * as api from '@/api'
import DiagnosticReport from './components/diagnostic-report.vue'
import DiscussPrice from './components/discuss-price/index.vue'
import SubRequire from './components/sub-require/index.vue'
import ContractInfo from './components/contract-info/index.vue'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    DiagnosticReport,
    DiscussPrice,
    SubRequire,
    ContractInfo
  },
  data () {
    return {
      // 页面loading
      loading: false,
      // 详情
      detail: {
        title: '', // 需求名称
        serviceTypeId: '', // 需求类别
        serviceTypeName: '',
        industryId: '', // 行业领域
        industryName: '',
        technicalId: '', // 技术领域
        technicalName: '', //
        description: '', // 需求描述
        keywords: '', // 关键词
        budget: '', // 预算金额
        enterpriseName: '', // 需求所属企业名称
        socialCreditCode: '', // 统一社会信用代码
        province: '', // 企业所在区域
        contacts: '', // 联系人
        contactMobile: '', // 联系手机
        contactEmail: '', // 联系邮箱
        startAt: '', // 时限要求
        endAt: '', // 时限要求
        logicStatus: -1,
        quotationList: []
      }
    }
  },
  computed: {
    // 需求id
    requireId () {
      return this.$route.params.id || ''
    },
    // 需求状态
    reqStatus () {
      return this.detail.logicStatus
    },
    // 是否是被分配的需求: 被分配者id存在
    isDistributorReq () {
      return this.detail.distributor
    },
    // 是否是被正常接受的需求: 接受者id存在
    isReceiverReq () {
      return this.detail.receiver
    },
    // 当前账号是否是需求发布者
    isPublisher () {
      return this.detail.publisher === this.curUserId
    },
    // 当前账号是否是被分配者
    isDistributor () {
      return this.detail.distributor === this.curUserId
    },
    // 当前账号是否是接受者
    isReceiver () {
      return this.detail.receiver === this.curUserId
    },
    // 是否是乙方
    isPartyB () {
      return this.isDistributor || this.isReceiver
    },
    // 此需求是否被分配给集成商用户
    isDistributorAggregator () {
      return this.isDistributorReq && this.detail.distributorRole === globalVar.USER_ROLE_2
    },
    // 能否显示其他模块信息：状态!== 已提交或已取消
    showModules () {
      /**
       * 除已提交、已取消两种状态
       */
      return [
        globalVar.REQUIRE_STATUS_SUBMITTED,
        globalVar.REQUIRE_STATUS_CANCELLED
      ].indexOf(this.reqStatus) === -1
    },

    // 是否显示诊断报告
    showDiagnosticReport () {
      // 被分配 复杂需求 已接受
      return this.isDistributorReq && this.detail.reportFlag && [
        globalVar.REQUIRE_STATUS_RECEIVED,
        globalVar.REQUIRE_STATUS_CONFIRMED
      ].indexOf(this.reqStatus) !== -1 && this.diagnosticReport
    },
    // 是否显示价格磋商模块: 磋商列表大于0
    showDiscussPrice () {
      return this.detail.quotationList.length > 0
    },
    /**
     * 是否显示合同信息
     */
    showContractInfo () {
      return this.detail.contractResult && this.detail.contractResult.finalContract
    },
    /**
     * 是否显示子需求
     */
    showSubRequire () {
      return this.detail.cooperationMode
    },
    /**
     * 显示服务提供商的信息
     */
    showServiceOrg () {
      return this.isDistributorReq ? (
        [
          globalVar.REQUIRE_STATUS_SUBMITTED,
          globalVar.REQUIRE_STATUS_CANCELLED
        ].indexOf(this.reqStatus) === -1
      ) : this.isReceiverReq ? (
        [
          globalVar.REQUIRE_STATUS_SUBMITTED,
          globalVar.REQUIRE_STATUS_CANCELLED,
          globalVar.REQUIRE_STATUS_PUBLISHED
        ].indexOf(this.reqStatus) === -1
      ) : false
    }
  },
  created () {
    // 获取需求详情
    this.getRequireFormDetail()
  },
  methods: {
    // 获取需求详情
    getRequireFormDetail () {
      this.loading = true
      api.getRequireFormDetail(this.requireId).then(res => {
        if (res.data.code === 0) {
          console.log('需求详情', res.data.data)
          this.detail = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 820px;
      margin: 0 auto;
      .info_module {
        padding: 10px 30px;
        border: 1px solid #DCDFE6;
        border-radius: 6px;
        & > h1 {
          font-size: 16px;
          font-weight: bold;
          line-height: 60px;
          color: #303133;
        }
        .module_main {
          padding: 0 10px;
        }
        &+.info_module {
          margin-top: 10px;
        }
      }
      .base_info {
      }
    }
  }
}
</style>
