<!-- 诊断报告 只会存在服务集成商接受了需求，上传了诊断报告，让需求发布方来下载，接受或拒绝代理才会存在 -->
<template>
  <div class="info_module">
    <h1>诊断报告</h1>
    <div class="module_main">
      <info label-width="140px">
        <info-item
          label="报告文件">
          <el-button
            v-if="detail.diagnosticReport"
            type="primary"
            plain
            size="small"
            @click="onDownload">
            点击下载
          </el-button>
          <template v-else>暂无文件</template>
        </info-item>
      </info>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    isPublisher () {
      return this.detail.publisher === this.userInfo.id
    }
  },
  methods: {
    onDownload () {
      window.open(`${this.detail.diagnosticReport.url}/download`)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
