<!-- 子需求 -->
<template>
  <div class="info_module">
    <div class="module_header">
      <h1>子需求</h1>
    </div>
    <div class="module_main">
      <div class="tw-flex tw-flex-wrap tw-justify-between tw-pt-10px tw-pb-30px">
        <div
          class="sub_item"
          v-for="item in requireList"
          :key="item.id"
          @click="getItemInfo(item)">
          <div class="item_left">
            <h1>{{ item.title }}</h1>
            <p>{{ item.description }}</p>
          </div>
          <div class="item_right">
          </div>
        </div>
      </div>
    </div>

    <!-- 子需求信息 -->
    <sub-require-info
      :visible.sync="infoDialogVisible"
      :require-id="selectedId">
    </sub-require-info>
  </div>
</template>

<script>
import * as api from '@/api'
import SubRequireInfo from './sub-require-info.vue'

export default {
  components: {
    SubRequireInfo
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      // 子需求信息
      infoDialogVisible: false,
      // 子需求列表
      requireList: [],
      // 当前被选择的id
      selectedId: ''
    }
  },
  created () {
    this.getRequireList()
  },
  methods: {
    getRequireList () {
      api.getSubRequireList(this.detail.id, {
        pageSize: 100,
        pageNum: 1
      }).then(res => {
        if (res.data.code === 0) {
          this.requireList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getItemInfo (item) {
      this.infoDialogVisible = true
      this.selectedId = item.id
    }
  }
}
</script>

<style lang="scss" scoped>
  .info_module {
    .module_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      & > h1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #303133;
      }
    }
    .sub_item {
      display: flex;
      justify-content: space-between;
      width: 360px;
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      border-radius: 6px;
      padding: 16px 20px 20px 20px;
      transition: all 0.3s ease;
      cursor: pointer;
      margin-bottom: 20px;
      .item_left {
        flex: 0 0 240px;
        & > h1 {
          font-size: 18px;
          font-weight: bold;
          line-height: 40px;
          color: #303133;
          &:hover {
            color: #3473E6;
          }
        }
        & > p {
          min-height: 60px;
          line-height: 20px;
          font-size: 14px;
          color: #909399;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          margin: 10px 0 50px 0;
        }
        .left_footer {
          display: flex;
          align-items: center;
          height: 32px;
        }
      }
      .item_right {
        .delete_text_btn {
          font-size: 14px;
          line-height: 20px;
          color: #F36A6E;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &:hover {
        border: 1px solid #3473E6;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        & > h1 {
          color: #3c424d;
        }
      }
    }
  }
</style>
